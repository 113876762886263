import React, { useEffect, useState } from 'react';
import { BotAlerts, tradersTalk } from '../../services/UserServices';
import AwesomeSlider from 'react-awesome-slider';
import moment from 'moment';
import AboutFooter from '../footer/AboutFooter';
import NavTop from '../nav/NavTop';
import { useNavigate } from 'react-router-dom';
import TickerBar from '../TickerBar/TickerBar';

const truncateText = (text, maxWords) => {
  const words = text.split(' ');
  if (words.length > maxWords) {
    return {
      truncated: words.slice(0, maxWords).join(' ') + '...',
      fullText: text,
    };
  }
  return { truncated: text, fullText: text };
};

const TardersTalk = () => {
  const [news, setNews] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const getBotAlerts = async () => {
    try {
      const response = await BotAlerts();
      if (response.status === 200) {
        const data = response.data;
        setNews(data.news || []);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleStockSearch = (ticker) => {
    navigate(`/search/${ticker}`);
  };

  const getData = async () => {
    try {
      const response = await tradersTalk();
      const data = response.data.response.ranks;
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBotAlerts();
    getData();
  }, []);

  return (
    <>
      <NavTop />
      <TickerBar />
      <div className='container-fluid'>
        <div className="dashboard-area bg-color my-5">
          <div className="row">
            {/* Trending Section as a Normal Table */}
            <div className="col-12 mb-3">
              <div className="searching_Reault_Right_area h-100">
                <h2 className="watchlisthead Heading_content_hp mb-2">Trending</h2>
                <div className="table-responsive">
                  <table className="table text-white">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Symbol</th>
                        <th>Name</th>
                        <th>Last Price</th>
                        <th>% Change</th>
                        <th>Volume</th>
                        <th>52-wk High</th>
                        <th>52-wk Low</th>
                        <th>Market Cap</th>
                        <th>P/E Ratio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        data.map((item, index) => {
                          const { truncated, fullText } = truncateText(item.stock.name, 75);
                          return (
                            <tr key={index} onClick={() => handleStockSearch(item?.stock?.symbol)}>
                              <td>{item.rank}</td>
                              <td>{item.stock.symbol}</td>
                              <td>
                                {truncated}
                                {truncated !== fullText && (
                                  <span className="read-more" onClick={() => alert(fullText)}> Read More</span>
                                )}
                              </td>
                              <td>{item.pricing.price.toFixed(2)}</td>
                              <td style={{ color: item.pricing.percentage_price_change > 0 ? "green" : "red" }}>
                                {item.pricing.percentage_price_change.toFixed(2)}%
                              </td>
                              <td>{item.fundamentals.volume ? item.fundamentals.volume.toLocaleString() : "N/A"}</td>
                              <td>{item.fundamentals.high_52_week || "N/A"}</td>
                              <td>{item.fundamentals.low_52_week || "N/A"}</td>
                              <td>{item.fundamentals.market_cap ? `${item.fundamentals.market_cap.toLocaleString()} B` : "N/A"}</td>
                              <td>{item.fundamentals.pe_ratio || "N/A"}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">No data available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* News Section as a Table */}
            <div className="col-12 mb-3">
              <div className="searching_Reault_left_area h-100">
                <h2 className="watchlisthead Heading_content_hp mb-2">News</h2>
                <div className='news-height'>
                  <table className="table text-white">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Share Float</th>
                        <th>Market Cap</th>
                        <th>Short Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {news.length > 0 ? (
                        news.map((item, index) => {
                          const { truncated, fullText } = truncateText(item.title, 75);
                          return (
                            <tr key={index}>
                              <td>
                                {truncated}
                                {truncated !== fullText && (
                                  <span className="read-more" onClick={() => alert(fullText)}> Read More</span>
                                )}
                              </td>
                              <td>{item.share_float}</td>
                              <td>{item.market_cap}</td>
                              <td>{item.short_percent}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">No news available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutFooter />
    </>
  );
};

export default TardersTalk;
